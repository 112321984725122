import React from 'react';
import './FaucetPage.css';

const FaucetPage = () => {
  const buttonStyle = {
    backgroundColor: '#74FB8DFF',
    borderColor: '#74FB8DFF',
    color: '#000000',
    width: '100%',
    maxWidth: '200px',
    minWidth: 'fit-content'
  };

  return (
    <div className="container-fluid page">
      <div className="header mt-5 mb-3">OUM Network</div>
      <div className="subtext2 mb-2">AI is coming to the Optimism Superchain...</div>
      
      <div className="container mt-4">
        <div className="container-content d-flex flex-column align-items-center">
          <a
            href="https://faucet.oum.network"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success"
            style={buttonStyle}
          >
            Claim Tokens
          </a>
        </div>
        <div className="mt-3 text-center" style={{color: '#74FB8DFF', fontSize: '1rem'}}>
Airdrop information coming soon.        </div>
      </div>
    </div>
  );
};

export default FaucetPage;